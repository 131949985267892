import { restApi } from "@/http/http.config";
import { encodeWithParam } from "@/util/Base64Encoding";
import jwt_decode from "jwt-decode";

const initialState = () => {
    return {
        preAssignZone: false,
        changeZone: false,
        viewMaskPII: false,
        editTaxPckt: false,
        switchEarnings: false,
        adjudicatCheckr: false,
        viewAccounting: false,
        viewDeposits: false,
        dwnldDeposits: false,
        editCandidPII: false,
        dwnldTaxPckt: false,
        editPartnerPII: false,
        empRole: "",
        viewDocuments: false,
        dwnldDocuments: false,
        uploadHCR:false,
        viewAdutTrial:false,
        resendAppLink:false,
        viewUpldInsurance:false,
        addZone:false,
        editZone:false,
        addEmployee:false,
        editEmployee:false,
        rwNotes:false,
        sendComms:false,
        withdrawApplicant:false,
        approveApplicant:false,
        addTestPartner:false,
        viewDriversApprovedDocs:false,
        viewReports:false,
        addThreshold:false,
        editThreshold:false,
        onOffLocations:false,
        editHCR:false,
        viewInsuranceHistory:false,
        viewAllPII:false,
        viewAdlPII:false,
        editSegmentCandidatePII: false,
        editSegmentPartnerPII: false,
        addHCR:false,
        changeHCR:false,
        viewTax: false,
        viewAdhoc: false,
        viewSSNHistoryCandidate:false,
        viewSSNHistoryPartner:false
    }
}

const state = initialState();

const getters = {
    getPermission: (state) => (permission) => {
        return state[permission];
    }
}

const mutations = {
    updatePermissions(state, permissions) {
        Object.keys(state).forEach(key=>{
            state[key] = false;
        })
        permissions.forEach((val) => {
            if (Object.keys(state).includes(val.state_key)) state[val.state_key] = true
        })
    },

    updateEmpRole(state, empRole) {
        state.empRole = empRole;
    }
}

const actions = {
    fetchPermissions: async ({ commit }) => {
        const token = window.localStorage.getItem("access_token");
        const decoded = jwt_decode(token);
        if (decoded.role) {
            commit("updateEmpRole", decoded.role);
            const res = await restApi.post('/employee/fetchPermissionsForRole', encodeWithParam({ role: decoded.role }));
            commit("updatePermissions", res?.data?.result?? []);
        }
    }
};

export default { state, getters, mutations, actions };