<template>
    <fragment>
       <!-- Due for Disbursements -->
    <p style="font-weight:600">Due for Disbursements</p>
        <div class="flex" style="width: 66%; margin-bottom: 19px;" ref="payProviders"> 
          <div class="payProvider-container hlgt-block">
            <div class="totals-text">{{ getGlobalTotalTextLabels().drivers }}</div>
            <div class="amount-block" style="width:95%">
              <p :class="totalView()" class="totals-num">{{ totalPartners }}</p>
            </div>
          </div>
          <div class="payProvider-container hlgt-block">
            <div class="totals-text">{{ getGlobalTotalTextLabels().one }}</div>
            <div class="amount-block" style="width:95%">
              <p :class="totalView()" class="totals-num">{{ totalONE | formatMoney }}</p>
            </div> 
          </div>
          <div class="payProvider-container hlgt-block">
            <div class="totals-text">{{ getGlobalTotalTextLabels().ach }}</div>
            <div class="amount-block" style="width:95%">
              <p :class="totalView()" class="totals-num">{{ totalACH | formatMoney }}</p>
            </div>
          </div>
          <div class="payProvider-container hlgt-block">
            <div class="totals-text">{{ getGlobalTotalTextLabels().branch }}</div>
            <div class="amount-block" style="width:95%">
              <p :class="totalView()" class="totals-num">{{ totalBRANCH | formatMoney }}</p>
            </div>
          </div>
        </div>
      <div style="display: flex;align-items: baseline;">
      <div :class="getBottonsWidth()">
        <button  class="custom-file-input custom-file-input-label" @click="downloadSampleCsv" style="border:0px"> Download Sample Template</button>
        <label for="fileInputs" class="custom-file-input upload" :style="{ opacity: !isProcessing ? '0.5' : '1',cursor: !isProcessing ? 'not-allowed' : 'pointer' }"
        style="margin-bottom: 0px;position: relative">
            <span class="custom-file-input-label ">Upload File</span>
          </label>
          <div >
            <input type="file" @change="handleFileUpload" ref="fileInput" id="fileInputs" accept=".csv" :disabled="!isProcessing">
          </div>
      </div>
      <div>
        <button-bar :buttons="paymentProcessorButtons" @onSelect="buttonSelected" />
      </div>
      <div>
          <date-picker v-model="dateRange" range format="DD-MMM-YYYY" :disabled-date="(date) => this.displayView === 'hold'? date >= new Date(): false" @input="fetchFilteredView('date', $event)"></date-picker>
      </div>
    </div>
      <!-- ERROR MESSAGE POPUP -->
      <div v-show="isPopup" class="menu-card" style="height: auto; max-width: 600px; background:#eee; color: white; position: absolute;z-index: 6; border-radius: 8px; box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);"
        :style="{top:`${infTop}px`,left:`${500}px`,boxShadow: '2px 2px 9px 2px rgba(0,0,0,0.5)'}">
        <div style="padding: 15px;display: flex;flex-direction: column;gap: 13px;">
          <div style="font-size: 16px; font-weight: bold;color:black;margin-left:20px">{{PopupMessage}}</div>
          <div style="margin-top: 10px; margin-left:92px"><button @click="hidePopup" style="padding: 8px 15px; background: #fff; color: black; border: none; border-radius: 4px; cursor: pointer;">Cancel</button></div>
        </div>
      </div>
      <!-- ERROR TYPE WITH COUNT POPUP -->
       <div v-show="isErrorType && error_type!=null" :style="{ width: '46%', top:`${MenuTop-350}px`, left:`${MenuLeft}px`,position:'absolute',zIndex:8,boxShadow: '2px 2px 9px 2px rgba(0,0,0,0.5)' }">
          <div>
            <button @click="hideErrorType" style="position: absolute; top: 4px; right: 4px; z-index:6">Close</button> 
          <table style="border-collapse: collapse;" class="table">
          <thead  class="menu table">
            <tr class="table" style="position:sticky;top:23px;background:#eee">
              <th class="heading table box-size-1" style="text-align: center;">Error</th>
              <th class="heading table box-size" style="text-align: center;">Count</th>
            </tr>
          </thead>
          </table>
          <div class="table-content">
          <table class="table" style="border-collapse: collapse;">
            <tbody >
              <tr v-for="(value, key) in error_type" :key="key" style="background:#fff" class="table"> 
                <td class="box-size table" style="text-align: left;padding: 3px 10px;"><p>{{ key }}</p></td>
                <td class="box-size table" style="text-align: right;padding: 3px 10px;"><p style="text-align: center;margin-left: 50px;">{{ value }}</p></td>
            </tr>
            </tbody>
         </table>
         </div>
	      </div>
      </div>
      <!-- DATA TABLE SUMMARY DATA -->
      <div :class="getPagination()">
        <!-- <div style="position:relative;"> -->
        <data-table :sticky-header="true" :entries="adochPaymentViewEntries" :headers="adochPaymentViewHeaders"
        :loading="loader" :remoteSort="true" :pagination-total-count="totalPaginationCount" :pagination-per-page="perPage" :pagination-current-page="currentPage"
        @onPaginate="fetchData($event)" :displayView="displayView">
        <template #date_of_upload="{ item }">
          <table>
            <thead>
              <tr>{{item.date_of_upload}}</tr>
            </thead>
          </table>
        </template>
        <template #csv_file_name="{ item }">
          <table>
            <thead>
            <a @click="viewFileDetails(item,$event)"><tr>{{item.csv_file_name}}</tr></a>
            </thead>
          </table>
        </template>
         <template #record_count="{ item }">
           <table>
            <thead class="number-center">
              <tr>{{item.record_count || recordCount }}</tr>
            </thead>
          </table>
         </template>
         <template v-if="selectedButton=='partners'" #total_unique_driver_count="{ item }">
           <table>
            <thead class="number-center">
              <tr>{{item.total_unique_driver_count || uniqueDriverCount }}</tr>
            </thead>
          </table>
         </template>

         <template #error_count="{ item }">
          <table>
            <thead v-if = "item.error_count > 0" class="number-center">
              <a @click="errorTypeCount(item, $event)"><tr>{{ item.error_count || errorCount}}</tr></a>
            </thead>
            <thead v-else class="number-center">
              <tr>{{ item.error_count || errorCount}}</tr>
            </thead>
          </table>
         </template>
         <template #disburse_count="{ item }">
           <table>
            <thead v-if="selectedButton=='count'" class="number-center">
              <tr>{{item.disburse_count || 0 }}</tr>
            </thead>
            <thead v-else-if="selectedButton=='amount'" class="number-center">
              <tr>{{item.disburse_amount ||'0.00' |formatMoney }}</tr>
            </thead>
            <thead v-else class="number-center">
              <tr>{{item.disburse_partners ||'0'}}</tr>
            </thead>
          </table>
         </template>
         <template #settled_count="{ item }">
           <table>
            <thead v-if="selectedButton=='count'" class="number-center">
              <tr>{{item.settled_count || 0 }}</tr>
            </thead>
            <thead v-else-if="selectedButton=='amount'"  class="number-center">
              <tr>{{item.settled_amount ||'0.00' |formatMoney}}</tr>
            </thead>
            <thead v-else class="number-center">
              <tr>{{item.settled_partners ||'0'}}</tr>
            </thead>
          </table>
         </template>
         <template #hold_count="{ item }">
           <table>
            <thead v-if="selectedButton=='count'" class="number-center">
              <tr>{{item.hold_count || 0 }}</tr>
            </thead>
            <thead  v-else-if="selectedButton=='amount'" class="number-center">
              <tr>{{item.hold_amount ||'0.00' |formatMoney}}</tr>
            </thead>
            <thead v-else class="number-center">
              <tr>{{item.hold_partners ||'0'}}</tr>
            </thead>
          </table>
         </template>
         <template #status_of_csv="{ item }">
          <table>
            <thead>
              <tr>
                <div>
                    {{ getStatusOfCsv(item)}}
                </div>
            </tr>
            </thead>
          </table>
         </template>
         <template #uploaded_by="{ item }">
          <table>
            <thead>
              <tr>
                <div>
                  {{ item.uploaded_by}}
                </div>
            </tr>
            </thead>
          </table>
         </template>
         <template #pay_initiated_by="{ item }">
          <table>
            <thead>
              <tr>
                <div>
                  {{ item.pay_initiated_by}}
                </div>
            </tr>
            </thead>
          </table>
         </template>
         <template #pay_initiated_on="{ item }">
          <table>
            <thead>
              <tr>
                <div>
                  {{ item.pay_initiated_on}}
                </div>
            </tr>
            </thead>
          </table>
         </template>
         <template #delete="{ item }">
          <table>
            <thead>
              <div :style="{ opacity: (getStatusOfCsv(item)==='Payment in progress' || getStatusOfCsv(item) === 'Partially Disbursed' || getStatusOfCsv(item) === 'Disbursement Initialized'||getStatusOfCsv(item) === 'Fully Disbursed' || isUploadedUser(item)) ? 0.3 : 1, pointerEvents: (getStatusOfCsv(item) === 'Payment in progress' || getStatusOfCsv(item) === 'Partially Disbursed' || getStatusOfCsv(item) === 'Disbursement Initialized'||getStatusOfCsv(item) === 'Fully Disbursed' || isUploadedUser(item)) ? 'none' : 'auto' }" >
                <tr @click="deleteFile(item)" class="icon-trash-2" style="font-size: 18px;cursor: pointer;" 
                ></tr>
              </div>
            </thead>
          </table>
         </template>
         </data-table>
        <!-- </div> -->
     </div>
    <div v-if="loader"><span class="loading"><Loader /></span></div>
    <!-- ADHOC MODAL LEADS TO NEW PAGE THAT HAS THE CSV DETAILS -->
    <adoch-csv-modal 
      adoch-modal-name="adochCsvTab" 
      :csv-details-id="csv_details_id" 
      :csv-file-name="csvFileName" 
      :csv-uploaded-date = "csvUploadedDate"
      :csv-status = "csvStatus"
      :forceBool = "forceBool"
      @modalClosed="handleModalClosed"
    />
    <!-- DELETE POPUP -->
    <dialoge-modal dialoge-modal-name="dialogeTab"  @deleteConfirmed="handleDeleteConfirmation"
    :dialoge-message = "dialogeMessage"
    :csv-dump-id="csv_dump_id"
    />
    </fragment>
</template>

<script>
import { DataTable,TableHeader,Loader } from "@/components";
import { Fragment } from "vue-fragment";
import { reconciliationApi } from "@/http/http.config";
import { encodeWithParam } from "@/filters";
import DateTime from "luxon/src/datetime";
import micromodal from "micromodal";
import AdochCsvModal from "@/modules/admin/accounting/adhoc/AdochCsvModal";
import DialogeModal from "@/modules/admin/accounting/adhoc/DialogeModal";
import { mapGetters } from "vuex";
import ClickOutside from "vue-click-outside";
import ButtonBar from "@/components/buttons/ButtonBar";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  components: {
    Fragment,
    DataTable,
    Loader,
    AdochCsvModal,
    DialogeModal,
    ButtonBar,
    DatePicker,
  },
  directives: { ClickOutside },
  data: () => ({
    adochPaymentEntries : [],
    totalPaginationCount: 0,
    perPage: 50,
    currentPage: 1,
    offset: 0,
    displayView: "adoch payment view",
    dateRange: null,
    loader: false,
    csvFile: null,
    csvFileName: "",
    csv_dump_id : "",
    csv_details_id : "",
    recordCount : 0,
    csvStatus : '',
    errorCount : 0,
    errorDetails : '',
    isView : false,
    isDelete : false,
    csvUploadedDate : '',
    PopupMessage : '',
    isPopup : false,
    infTop : 0,
    isProcessing : true,
    dialogeMessage : "",
    error_type : {},
    isErrorType : false,
    MenuTop : 0,
    MenuLeft : 0,
    forceBool: false,
    selectedButton: "count",
    totalONE : 0,
    totalACH : 0,
    totalBRANCH : 0,
    totalPartners : 0,
    allChunksUploaded : true,
    isBufferDataUploaded : true,
    uniqueDriverCount : 0
  }),
  mounted() {
    this.fetchFilteredView('view',['adoch payment view']);
  },
  computed: {
    ...mapGetters(["getUserID"]),
    adochPaymentViewHeaders() {
        if(this.selectedButton == 'partners'){
          return [
            new TableHeader({ headerClass: "cell7", label: "Date Uploaded", name: "date_of_upload", cellContents: "date_of_upload", cellClass: "font-12" }),
            new TableHeader({ headerClass: "cell2", label: "CSV Filename", name: "csv_file_name", cellContents:"csv_file_name", cellClass: "font-12" }),
            new TableHeader({ headerClass: "cell8", label: "Unique Driver", name: "total_unique_driver_count", cellContents: "total_unique_driver_count", cellClass: "font-12" }),
            new TableHeader({ headerClass: "cell4", label: "Disb", name: "disburse_count", cellContents: "disburse_count", cellClass: "font-12" }),
            new TableHeader({ headerClass: "cell4", label: "Settled", name: "settled_count", cellContents: "settled_count", cellClass: "font-12" }),
            new TableHeader({ headerClass: "cell4", label: "Held", name: "hold_count", cellContents: "hold_count", cellClass: "font-12" }),
            new TableHeader({ headerClass: "cell4", label: "Status", name: "status_of_csv", cellContents: "status_of_csv", cellClass: "font-12" }),
            new TableHeader({ headerClass: "cell8", label: "Uploaded By", name: "uploaded_by", cellContents: "uploaded_by", cellClass: "font-12" }),
            new TableHeader({ headerClass: "cell8", label: "Disb Inititaed by", name: "pay_initiated_by", cellContents: "pay_initiated_by", cellClass: "font-12" }),
            new TableHeader({ headerClass: "cell8", label: "Disb Inititated on", name: "pay_initiated_on", cellContents: "pay_initiated_on", cellClass: "font-12" }),
            new TableHeader({ headerClass: "cell2", label: "Delete", name: "delete",  cellContents: "delete",cellClass: "font-12" }),
          ];
        }else{
          return [
            new TableHeader({ headerClass: "cell7", label: "Date Uploaded", name: "date_of_upload", cellContents: "date_of_upload", cellClass: "font-12" }),
            new TableHeader({ headerClass: "cell2", label: "CSV Filename", name: "csv_file_name", cellContents:"csv_file_name", cellClass: "font-12" }),
            new TableHeader({ headerClass: "cell4", label: "Records", name: "record_count", cellContents: "record_count", cellClass: "font-12" }),
            new TableHeader({ headerClass: "cell4", label: "Errors", name: "error_count", cellContents: "error_count", cellClass: "font-12" }),
            new TableHeader({ headerClass: "cell4", label: "Disb", name: "disburse_count", cellContents: "disburse_count", cellClass: "font-12" }),
            new TableHeader({ headerClass: "cell4", label: "Settled", name: "settled_count", cellContents: "settled_count", cellClass: "font-12" }),
            new TableHeader({ headerClass: "cell4", label: "Held", name: "hold_count", cellContents: "hold_count", cellClass: "font-12" }),
            new TableHeader({ headerClass: "cell4", label: "Status", name: "status_of_csv", cellContents: "status_of_csv", cellClass: "font-12" }),
            new TableHeader({ headerClass: "cell8", label: "Uploaded By", name: "uploaded_by", cellContents: "uploaded_by", cellClass: "font-12" }),
            new TableHeader({ headerClass: "cell8", label: "Disb Inititaed by", name: "pay_initiated_by", cellContents: "pay_initiated_by", cellClass: "font-12" }),
            new TableHeader({ headerClass: "cell8", label: "Disb Inititated on", name: "pay_initiated_on", cellContents: "pay_initiated_on", cellClass: "font-12" }),
            new TableHeader({ headerClass: "cell2", label: "Delete", name: "delete",  cellContents: "delete",cellClass: "font-12" }),
          ];
        }
      },
      adochPaymentViewEntries() {
        return this.adochPaymentEntries;
      },
      paymentProcessorButtons() {
      return [
        { id: "count", label: "COUNT" },
        { id: "amount", label: "AMOUNT" },
        { id: "partners", label: "PARTNERS" },
      ];
    },
  },
  methods : {
    fetchData(event) {
      this.loader = true;
      this.currentPage = Number(event.pageNumber);
      this.offset = event.offset;
      this.fetchFilteredView('','',false);
    },
    getDateRange() {
      let start = DateTime.now().setZone("America/Los_Angeles").startOf("day").minus({ days: 30 }).toString().replace("T", " ").substr(0,23);
      let end = DateTime.now().setZone("America/Los_Angeles").endOf("day").plus({ days: 9 }).toString().replace("T", " ").substr(0,23);
       
      if (this.dateRange != null && this.dateRange[1] != null) {
        [start, end] = [`${new Date(this.dateRange[0]).toISOString()?.substr(0,10)} 00:00:00.000`, `${new Date(this.dateRange[1]).toISOString()?.substr(0,10)} 23:59:59.999`];
        return { start, end };
      }
      return { start, end };
      },
      hidePopup() {
        this.isPopup = false;
      },
      async handleModalClosed() {
        await this.fetchFilteredView('',"adoch payment view");
      },
      async fetchFilteredView(type,value,resetPagination = true) {
          this.loader = true;
          if (type === "view") {
            this.displayView = value[0] || this.displayView;
            this.dateRange = [];
          }
          if(this.displayView == "adoch payment view" && resetPagination) {
            this.currentPage = 1,
            this.offset = 0
          }
          const { start, end } = this.getDateRange();
          let params = {
            limit : this.perPage,
            offset : this.offset,
            startDate : start,
            endDate : end
          }
          if (!this.dateRange[0] || !this.dateRange[1]){
            params.startDate = null;
            params.endDate = null;
          }
          //Getting the Disbursement amounts
          const totals = (await reconciliationApi.post("/getDuePaymentsforAdhoc"))?.data;
          this.totalONE = totals.totalONE.disbursement_amount;
          this.totalACH = totals.totalACH.disbursement_amount;
          this.totalBRANCH = totals.totalBRANCH.disbursement_amount;
          this.totalPartners = totals.totalPartners.total_disbursemet_partners

          const aData = (await reconciliationApi.post("/getAdochSummary", encodeWithParam({params})))?.data;
          this.adochPaymentEntries = aData.result;
          this.totalPaginationCount = Number(aData?.rowCount);
          this.loader = false;
      },
      // FILE UPLOAD
      async handleFileUpload(event) {
        this.isProcessing = false;
        this.csvFile = event.target.files[0];
        if(this.csvFile.type === 'text/csv'){
          let param = {
          fileName : this.csvFile.name,
          recordCount : 0,
          status : 'Validation in progress',
          errorCount : 0,
          // errorDetails : NULL,
          uploadedBy : this.$store.getters.getUserID
        }
        this.$refs.fileInput.value = '';
        const iData = (await reconciliationApi.post("/insertCsvDump", encodeWithParam({param})))?.data;
        if(iData.success) {
          this.csv_dump_id = iData?.result[0]?.csv_dump_id;
          await this.fetchFilteredView('',"adoch payment view");
          await this.uploadFile();
        } else if(!iData.success && iData?.message.includes('duplicate key value violates unique constraint')) {
          this.PopupMessage = "Duplicate Files are not Allowed....";
          this.infTop = 205;
          this.isProcessing = true;
          this.isPopup = true;
        }
        else {
          this.isProcessing = true;
        }
        } else {
          this.isProcessing = true;
          this.infTop = (event.target.getBoundingClientRect().top)+210;
          this.PopupMessage = 'Only CSV Files are Allowed';
          this.isPopup = true;
        }
    },
    // STATUS OF CSV
    getStatusOfCsv(item) {
      if(!item.status_of_csv_1 || item.status_of_csv_1 ==''){
        return item.status_of_csv
      } else {
          for (let item of item.status_of_csv_1) {
            if (!(item === 'PROCESSED' || item === 'PAID')) {
            return "Partially Disbursed";
          }
        }
       return "Fully Disbursed";
     }
    },
    isUploadedUser(item) {
      if(this.$store.getters.getUserID != item.user_id) {
        return true
      } else {
        return false
      }
    },
    // async uploadFile() {
    //    if (!this.csvFile) {
    //       this.isProcessing = true;
    //       alert("Please select a CSV file.");
    //       return;
    //    }
    //    const chunkSize = 600 * 1024; // 600KB chunks
    //    let offset = 0;
    //    let chunkcount = 1;
    //    let isSuccess = false;
    //    this.isBufferDataUploaded = true;

    //    const uploadChunk = async () => {
    //     if (offset >= this.csvFile.size) {
    //       return this.allChunksUploaded;
    //     }
    //     const totalChunks = Math.ceil(this.csvFile.size / chunkSize);

    //     while (totalChunks >= chunkcount && this.isBufferDataUploaded) {
    //       isSuccess = false;
    //       const chunk = this.csvFile.slice(offset, offset + chunkSize);
    //       const formData = new FormData();
    //       formData.append('file', chunk);
    //       formData.append("userID", this.$store.getters.getUserID);
    //       formData.append("acl", "public");
    //       formData.append("csv_dump_id", this.csv_dump_id);
    //       formData.append("chunkcount", chunkcount);
    //       formData.append("totalChunks", totalChunks);

    //       try {
    //         const response =  await reconciliationApi.post("/getCsvDetails", formData);
    //         // Check if the current chunk was uploaded successfully
    //         if (!response.data.success) {
    //           let data = response.data
    //           this.isBufferDataUploaded = false;
    //           await this.fetchFilteredView();
    //           let errorMessage;
    //           if (data.message.includes('invalid input syntax for type uuid')) {
    //             errorMessage = 'File has invalid data, Partner_id should have value'
    //           } else if(data.message.includes('invalid input syntax for type numeric')) {
    //               errorMessage = 'Amount should be a numeric value, aviod empty values'
    //           } else if (data.message.includes('invalid input syntax for type timestamp with time zone')) {
    //               errorMessage = 'Date column should not be empty'
    //           } else if (data.message.includes('violates check constraint "check_tax_exempted_value"')){
    //               errorMessage = 'Tax exempted value should be y or n'
    //           } else if (data.message.includes('duplicate key value violates unique constraint "csv_dump_id_partner_id_type_adhoc"')){
    //               errorMessage = 'Duplicate Values present in the CSV'
    //           } else if (data.message.includes('violates check constraint "check_deposit_action"')){
    //               errorMessage = 'Deposit Summary should be add or correct'
    //           } else if (data.message.includes('violates check constraint "check_reason_code"')){
    //               errorMessage = 'Reason Internal Should not be Empty'
    //          }
    //         this.PopupMessage = errorMessage || data.message ||"File data formate is not expected one, kindly delete and upload the updated File";
    //         this.infTop = 205;
    //         this.isPopup = true;
    //         this.isProcessing = true;
    //         this.allChunksUploaded = false;
    //         } else {
    //            isSuccess = true;
    //         }
    //       } catch (error) {
    //         this.isBufferDataUploaded = false;
    //         await this.fetchFilteredView();
    //         this.allChunksUploaded = false;
    //      }
    //         offset += chunkSize;
    //         chunkcount = chunkcount + 1;
    //     }
    //       if(chunkcount == totalChunks + 1 && isSuccess) {
    //       const sdata = (await reconciliationApi.post("/getValidation", encodeWithParam({csv_dump_id : this.csv_dump_id})))?.data;
    //       if(sdata?.success){
    //         this.isProcessing = true;
    //         await this.fetchFilteredView();
    //       }
    //     }
    //   };
    //     uploadChunk(); 
    // },
    async uploadFile() {
        if (!this.csvFile) {
          this.isProcessing = true;
            alert("Please select a CSV file.");
            return;
        }
        const formData = new FormData();
        formData.append("file", this.csvFile);
        formData.append("userID", this.$store.getters.getUserID);
        formData.append("acl", "public");
        formData.append("csv_dump_id", this.csv_dump_id)
        const data = (await reconciliationApi.post("/getCsvDetails", formData))?.data;
        if(data?.success) {
            if(data?.rowCount > 0) {
                await this.fetchFilteredView();
                this.isPopup = false;
            }
          this.isView = true;
          const sdata = (await reconciliationApi.post("/getValidation", encodeWithParam({csv_dump_id : this.csv_dump_id})))?.data;
          if(sdata?.success){
            this.isProcessing = true;
            await this.fetchFilteredView();
          }
        } else {
          //await reconciliationApi.post("/deleteCsvDump", encodeWithParam({csv_dump_id : this.csv_dump_id}))?.data;
          await this.fetchFilteredView();
          let errorMessage;
          if (data.message.includes('invalid input syntax for type uuid')) {
            errorMessage = 'File has invalid data, Partner_id should have value'
          } else if(data.message.includes('invalid input syntax for type numeric')) {
            errorMessage = 'Amount should be a numeric value, aviod empty values'
          } else if (data.message.includes('invalid input syntax for type timestamp with time zone')) {
            errorMessage = 'Date column should not be empty'
          } else if (data.message.includes('new row for relation "csv_dump_details" violates check constraint "check_tax_exempted_value"')){
            errorMessage = 'Tax exempted value should be y or n'
          } else if (data.message.includes('duplicate key value violates unique constraint "csv_dump_details_csv_dump_id_partner_id_transaction_type_key"')){
            errorMessage = 'Duplicate Values present in the CSV'
          } else if (data.message.includes('new row for relation "csv_dump_details" violates check constraint "check_deposit_action"')){
            errorMessage = 'Deposit Summary should be add or correct'
          } else if (data.message.includes('new row for relation "csv_dump_details" violates check constraint "check_reason_code"')){
            errorMessage = 'Reason Internal Should not be Empty'
          }
          this.PopupMessage = errorMessage || data.message ||"File data formate is not expected one, kindly delete and upload the updated File";
          this.infTop = 205;
          this.isPopup = true;
          this.isProcessing = true;
        }
    },
    async deleteFile (item) {
      this.isErrorType = false;
      this.csv_dump_id = item.csv_dump_id;
      this.dialogeMessage = "delete";
      micromodal.show("dialoge-modal");
    },
    async handleDeleteConfirmation(value) {
      const result = (await reconciliationApi.post("/deleteCsvDump", encodeWithParam({csv_dump_id : value})))?.data;
      if(result.success) {
        this.fetchFilteredView('',"adoch payment view");
      }
    },
    async viewFileDetails (item, event) {
        this.isErrorType = false;
        this.isPopup = false;
        if(item.status_of_csv != 'In Process' && item.status_of_csv != 'Validation in progress') {
          this.csvStatus = await this.getStatusOfCsv(item);
          this.csv_details_id = item.csv_dump_id;
          this.csvFileName = item.csv_file_name;
          this.csvUploadedDate = item.date_of_upload;
          document.body.style.overflow = "hidden";
          this.forceBool = !this.forceBool;
          micromodal.show("adoch-csv-modal");
        } else {
            this.infTop = (event.target.getBoundingClientRect().top - document.body.getBoundingClientRect().top)-250;
            this.PopupMessage = "Document Is Processing Pls Wait....";
            this.isPopup = true;
        }
    },
    getPagination(){
      return this.loader || this.totalPaginationCount == 0 ? '' : 'data-table';
    },
    getBottonsWidth() {
      return this.totalPaginationCount == 0 ? 'upl-down-button' : 'upl-down-buttons';
    },
    async errorTypeCount(item,event) {
      this.MenuTop = event.target.getBoundingClientRect().top - document.body.getBoundingClientRect().top;
      this.MenuLeft = event.target.getBoundingClientRect().left-40;
      this.error_type = item.error_details;
      this.isErrorType = true;
    },
    async hideErrorType () {
      this.isErrorType = false;
    },
    async downloadSampleCsv () {
      const adochPaymentHeaders = [
        "Partner ID",
        "Full Name",
        "Transaction Type",
        "Amount ($)",
        "Deposit Summary (Add/Correct)",
        "Transaction ID",
        "Deposit Summary Comments",
        "Reason Internal",
        "Tax Exempt",
      ];
      // const exampleRow = {
      //   "Partner ID": "Should be a UUID, No Empty Values at any cost",
      //   'Full Name': "First name and Last name Should present",
      //   "Transaction Type": "Trip Earnings,Incentives,Earnings Adjustment,Healthcare Subsidy,Tips Coverage,Adjustment Credits",
      //   "Amount ($)": "100.00",
      //   "Deposit Summary (Add/Correct)": "add (or) correct",
      //   "Transaction ID": "If deposit_action is 'correct' then this id is mandatory",
      //   "Deposit Summary Comments": "comments",
      //   "Reason Internal": "103",
      //   "Tax Exempt": "y (or) n",
      // };
      const csvData = [adochPaymentHeaders];
      const filename = "Adhoc_sample_file_format";
      const csv = this.$papa.unparse(csvData, {
      delimiter: ",",
      });
      this.$papa.download(csv, filename);
    },
    buttonSelected(button) {
      this.selectedButton = button.id;
    },
    getGlobalTotalTextLabels(){
          return {
            drivers : 'DRIVERS',
            one : 'ONE',
            ach : 'ACH',
            branch : 'BRANCH'
          }
      },
    // WILL PROVIDE DOLLAR SYMBOL
    formatMoney(amount, formatNotRequired = false) {
      amount == undefined || isNaN(amount) ? (amount = 0) : amount;
      if(formatNotRequired) return amount;
      return new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(amount)
    },
    totalView(){
      if(this.displayView === 'settlement view' && this.disbursementTotal === this.settlementTotal ){
        return 'green'
      } else if (this.displayView === 'settlement view'){
        return 'red-one'
       } else if (this.displayView === 'disbursement view' && this.disbursementTotal === this.settlementTotal){
        return 'green'
       }
       return 'red-one'
      },
  }
}
</script>
<style scoped>
 tbody tr:nth-child(odd) {
    background: none;
  }
  .data-table{
    margin-top: -85px;
  }
 .number-center {
    text-align: center;
 }
  input[type="file"] {
    display: none;
  }
.custom-file-input {
  display: inline-block;
  background-color: #3171ff;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
}
.custom-file-input-label {
  font-size: 16px;
}
.disabled {
  opacity: 0.5; 
  cursor: not-allowed; 
}
.upl-down-buttons{
  display: flex;
  justify-content: space-between;
  width: 30%;
}
.upl-down-button{
  display: flex;
  justify-content: space-between;
  width: 30%;
  margin-bottom: 20px
}
.table-content{
  overflow-y: auto;
  max-height: 250px;
}
.menu{
  background-color: #eee;
}
.box-size{
  width: 300px;
}
.box-size-1{
  width: 285px;
}
.table{
  border: 1px solid black;
}
.payProvider-container{
  display: flex;
  flex-direction: column;
  height: 100px;
}
.totals-text {
  font-size: 13px;
  font-weight: 600;
  margin: 10px;
  text-align: center;
}
.totals-num {
  font-size: 22px;
  font-weight: 600;
  color: red;
  text-align: center;
  margin-top: 4px;
  padding: 0 10px;
}
</style>