<template>
  <fragment>
    <data-table :headers="screeningTableHeaders" :entries="screeningTableEntries" hide-headers>
      <template #name="{ item }">
        <div v-if="item.editing" class="editing">
          <validation-observer v-slot="validation" slim>
            <div class="screening-actions">
              <save-cancel cancel-first no-margin emit-cancel :save-enabled="validation.dirty && validation.valid" @onCancel="cancel(item)" @onSave="save(item)" />
            </div>
            <div>
              <label class="section-header">
                <span>Edit {{ item.name }}</span>
              </label>
              <div v-if="isArray(item.data)">
                <fragment v-for="header in editableItems(item.data)" :key="`${header.label}-input`">
                  <date-picker v-if="header.type === 'date'" v-model="candidateDetails[header.key]" :label="getLabel(header)" :name="header.label" :width="254" inline-label />
                  <form-input v-else v-model="candidateDetails[header.key]" :label="getLabel(header)" :name="header.label" :width="254" inline-label rules="required" :type="header.type" />
                </fragment>
              </div>
              <form-input v-else v-model="candidateDetails[item.key]" label="" :name="item.name" :width="254" :type="item.type" invisible required autocomplete="new-password" :rules="item.rules" />
            </div>
          </validation-observer>
        </div>
        <div v-else class="reading">
          <div>
            <label class="section-header">
              <span>{{ item.name }}</span>
            </label>
            <table v-if="isArray(item.data)" class="display-data-table" style="width: 310px">
              <thead>
                <tr>
                  <th v-for="header in item.data" :key="`${header.label}-header`" :class="header.class">{{ header.label }}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td v-for="header in item.data" :key="`${header.label}-value`">
                    {{ header.value }}
                    <i v-if="!getPermission('viewMaskPII')" :class="checkIsMasked(header)" @click="toggleMaskDisplay(header.key)"></i>
                  </td>
                </tr>
              </tbody>
            </table>
            <p v-else class="display-data">{{ item.data }}</p>
          </div>
          <div class="screening-actions">
            <div v-if="isValid(item.status, item.name)">
              <div class="status validated"><i class="icon icon-check-circle"></i>Validated</div>
              <!-- <a @click="edit(item, item.id)">Edit&nbsp;<i class="icon icon-edit-2"></i></a> -->
            </div>
            <div v-else-if="isUnchecked(item.status)">
              <div class="status unchecked"><i class="icon icon-alert-triangle"></i>Unchecked</div>
              <a v-if="getPermission('viewUpldInsurance')" @click="item.validate">Validate&nbsp;<i class="icon icon-external-link"></i></a>
            </div>
            <div v-else>
              <div class="status invalid"><i class="icon icon-alert-octagon"></i>Invalid</div>
              <a v-if="item.validate && getPermission('viewUpldInsurance')" @click="item.validate">Validate&nbsp;<i class="icon icon-external-link"></i></a>
              <!-- <a v-else @click="edit(item)">Edit&nbsp;<i class="icon icon-edit-2"></i></a> -->
            </div>
          </div>
        </div>
      </template>
    </data-table>
    <preview-insurance-modal
      :id="'screening-table-insurance-preview'"
      :data="insuranceModalData"
      @onRemove="removeInsurance"
      @onSave="updateInsurance"
      @onCancel="closeModal"
      @imageChanged="insuranceImageUploaded"
    />
  </fragment>
</template>
<script>
import { DataTable, TableHeader, FormInput, SaveCancel } from "@/components";
import { DateTime } from "luxon";
import { extend, ValidationObserver } from "vee-validate";
import micromodal from "micromodal";
//import { GET_PARTNER_FILES } from "@/modules/admin/onboarding/graph/subscriptions";
import DatePicker from "@/components/forms/fields/DatePicker";
import { Fragment } from "vue-fragment";
import PreviewInsuranceModal from "@/modules/admin/onboarding/modals/PreviewInsuranceModal";
import { restApi } from "@/http/http.config";
import { encodeWithParam } from "@/util/Base64Encoding";
import { mapGetters } from "vuex";
import {FunnelStatus} from "@/util/funnelProgress";
extend("ssn", {
  message: (field) => `${field} is not valid.`,
  validate: (value) => {
    const ssnRegex = /^(?!219099999|078051120)(?!666|000|9\d{2})\d{3}(?!00)\d{2}(?!0{4})\d{4}$/g;
    return Promise.resolve({
      valid: value.match(ssnRegex),
    });
  },
});

export default {
  components: { DatePicker, SaveCancel, FormInput, DataTable, ValidationObserver, Fragment, PreviewInsuranceModal },
  props: {
    value: {
      type: Boolean,
      required: true,
      default: false,
    },
    candidate: {
      type: Object,
      required: false,
      default: () => {},
    },
    personalDetails: {
      type: Object,
      required: false,
      default: () => {},
    },
    partnerSteps: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data: () => ({
    insuranceImageURL: "",
    insuranceFileId: "",
    funnelFilesId: "",
    locationPartnerId: "",
    partnerUserId: "",
    candidateDetails: {
      license: {},
    },
    initialValues: {
      license: {},
    },
    screeningTable: [
      { 
        id: "ss-number", 
        key: "ssn", 
        name: "SS Number", 
        data: [
          { label: "SSN", value: "", key: "ssn", type: "password" },
          { label: "", value: "", key: "ssn-eye", isMasked: true}
        ], 
        editing: false, 
        status: "VALID", 
        rules: "ssn|ssnExists"
      },
      {
        id: "date-of-birth",
        name: "Date of Birth",
        data: [
          { label: "Date", value: "", key: "dob", editable: true, type: "date" },
          { label: "Age", value: "", key: "age", editable: false },
          { label: "", value: "", key: "dob-eye", isMasked: true}
        ],
        editing: false,
        status: "VALID",
      },
      {
        id: "drivers-license",
        name: "Drivers License",
        data: [
          { label: "State", value: "", key: "licenseState", class: "cell10", editable: true, type: "text" },
          { label: "Exp", value: "", key: "licenseExpiry", class: "cell55", editable: true, type: "date" },
          { label: "Number", editLabel: "Num", value: "", key: "licenseNumber", class: "cell35", editable: true, type: "text" },
          { label: "", value: "", key: "dl-eye", isMasked: true}
        ],
        editing: false,
        status: "VALID",
      },
      {
        id: "auto-insurance",
        name: "Auto Insurance",
        data: [{ label: "Exp", value: "", class: "cell100", editable: true, type: "date" }],
        editing: false,
        status: "UNCHECKED",
        validate: (data) => {
          console.log(data);
          micromodal.show("screening-table-insurance-preview", {});
        },
      },
    ],
  }),
  computed: {
    ...mapGetters(["getUsername", "getPermission"]),
    screeningTableHeaders() {
      return [new TableHeader({ name: "name", cellClass: "screening-data" })];
    },
    candidateID() {
      this.getPersonalDetails();
      return this.$props.candidate ? this.$props.candidate.id : null;
    },
    locationID() {
      return this.$props.candidate ? this.$props.candidate.locationID : null;
    },
    screeningTableEntries() {
      return this.screeningTable;
    },
    insuranceModalData() {
      return {
        insuranceImageURL: this.$props.candidate.insuranceImageURL,
        isPDF:this.$props.candidate.insuranceFileFormat,
        insuranceExpiry: this.handleInsuranceExpiryDate(this.$props.candidate.insuranceExpiry,'prop'),
        insuranceFileId: this.$props.candidate.insuranceFileId,
        locationPartnerID: this.candidateID,
        funnelFilesID: this.funnelFilesId,
        //partnerUserID: this.partnerUserId,
        partnerID: this.$props.candidate.partnerID,
        locationID: this.$props.candidate.locationID,
        documentTemplateID: this.getInsuranceDocumentsTemplateID(this.$props.candidate.allDocs),
        signature: this.$props.candidate.electronicSignature ? this.$props.candidate.electronicSignature : "",
        userID: this.$props.candidate.userId,
        phone: this.$props.candidate.phone
      };
    },
    getRole() {
      return localStorage.getItem("role");
    }
  },
  mounted() {
    this.getPersonalDetails();
    //this.getFiles();

    extend("ssnExists", {
      message: (field) => `${field} already exists`,
      validate(value) {
        if (["111112001", "111112002", "111112010"].includes(value)) {
          return Promise.resolve({
            valid: true,
          });
        }

        return restApi.post("/onboard/checkSSN", encodeWithParam({ ssn: value })).then((data) => {
          return Promise.resolve({
            valid: data.data.result.length > 0 ? false : true,
          });
        });
      },
    });
  },
  methods: {
    checkIsMasked(header) {
      if (this.getRole == "COORDINATOR") return "";
      else if (this.getRole == "ONBOARDING_SPECIALIST" && header.key == "dl-eye") return header.isMasked ? "eye icon-eye-off" : "eye icon-eye";
      else if (this.getRole != "ONBOARDING_SPECIALIST" && header.key?.includes("eye")) return header.isMasked ? "eye icon-eye-off" : "eye icon-eye";
    },
    toggleMaskDisplay(key) {
      if(!this.getPermission("viewAllPII")) return;
      if(key == "ssn-eye") {
        this.screeningTable[0].data[1].isMasked = false
        setTimeout(() => this.screeningTable[0].data[1].isMasked = true, 2000);
      } else if(key == "dob-eye") {
        this.screeningTable[1].data[2].isMasked = false;
        setTimeout(() => this.screeningTable[1].data[2].isMasked = true, 2000);
      } else {
        this.screeningTable[2].data[3].isMasked = false;
        setTimeout(() => this.screeningTable[2].data[3].isMasked = true, 2000);
      };
    },
    insuranceImageUploaded(data) {
      this.$emit("insuranceImageUploaded",data);
    },
    getInsuranceDocumentsTemplateID(docs) {
      if (docs?.length > 0) {
        let insuranceID;
        insuranceID = docs?.filter((item) => item.title.toLowerCase() === "insurance");
        return insuranceID[0].id;
      } else {
        return "";
      }
    },
    getPersonalDetails() {
      const { decoded, ssn, dob, licenseNumber, licenseExpiry, licenseState, insuranceExpiry } = this.$props.personalDetails;
      this.candidateDetails = {
        ssn,
        dob,
        licenseNumber,
        licenseExpiry,
        licenseState,
        insuranceExpiry,
        dssn: decoded?.dssn,
        ddob: decoded?.ddateOfBirth,
        dnumber: decoded?.dnumber,
        dexpiration: decoded?.dexpiration,
        dlicenseState: decoded?.dlicenseState
      };
      this.initialValues = { ...this.candidateDetails };
      this.screeningTable.forEach((screeningTableEntry) => {
        if (screeningTableEntry.id === "ss-number") {
          screeningTableEntry.data[0].value = screeningTableEntry.data[1].isMasked ? this.candidateDetails.ssn : this.candidateDetails.dssn;
          screeningTableEntry.status = this.candidateDetails.ssn ? "VALID" : "INVALID";
        } else if (screeningTableEntry.id === "date-of-birth") {
          screeningTableEntry.data[0].value = this.candidateDetails.dob && screeningTableEntry.data[2].isMasked ? this.candidateDetails.dob : this.formatDate(this.candidateDetails.ddob);
          screeningTableEntry.data[1].value = this.candidateDetails.dob && screeningTableEntry.data[2].isMasked ? "**" : this.roundAge(this.candidateDetails.ddob);
        } else if (screeningTableEntry.id === "drivers-license") {
          screeningTableEntry.data[0].value = this.candidateDetails.licenseState && screeningTableEntry.data[3].isMasked ? this.candidateDetails.licenseState : this.candidateDetails.dlicenseState;
          screeningTableEntry.data[1].value = this.candidateDetails.licenseExpiry && screeningTableEntry.data[3].isMasked ? this.candidateDetails.licenseExpiry : this.formatDate(this.candidateDetails.dexpiration);
          screeningTableEntry.data[2].value = this.candidateDetails.licenseNumber && screeningTableEntry.data[3].isMasked ? this.candidateDetails.licenseNumber : this.candidateDetails.dnumber;
        } else if (screeningTableEntry.id === "auto-insurance") {
          screeningTableEntry.data[0].value = this.$props.candidate.insuranceExpiry ? this.handleInsuranceExpiryDate(this.$props.candidate.insuranceExpiry,'display') : "";
          //screeningTableEntry.status = this.candidateDetails.insuranceExpiry ? "VALID" : "INVALID";
        }
      });
      const valid = this.screeningTable.reduce((isValid, tableEntry) => isValid && tableEntry.status === "VALID", true);
      this.$emit("input", valid);
    },
    isArray(data) {
      return data instanceof Array;
    },
    isValid(status, name) {
      if (name == "Auto Insurance") {
        const isCompleted = this.$props.partnerSteps.filter((progress) => {
          return (progress.status == FunnelStatus.OCR_MANUAL_CLEAR || progress.status === FunnelStatus.OCR_CLEAR);
        });
        return isCompleted.length > 0;
      }
      return status === "VALID";
    },
    isUnchecked(status) {
      return status === "UNCHECKED";
    },
    edit(data, name = "none") {
      if (name === "auto-insurance") {
        micromodal.show("screening-table-insurance-preview", {});
      } else {
        const tableEntry = this.screeningTableEntries.filter((screening) => screening.id === data.id);
        if (tableEntry && tableEntry.length) {
          tableEntry[0].editing = true;
        }
      }
    },
    closeModal() {
      micromodal.close("screening-table-insurance-preview");
    },
    cancel(data) {
      const tableEntry = this.screeningTableEntries.filter((screening) => screening.id === data.id);
      if (tableEntry && tableEntry.length) {
        tableEntry[0].editing = false;
      }
      if (this.isArray(data.data)) {
        data.data.forEach((item) => {
          if (item.key) {
            this.candidateDetails[`${item.key}`] = this.initialValues[`${item.key}`];
          }
        });
      } else {
        this.candidateDetails[`${data.key}`] = this.initialValues[`${data.key}`];
      }
    },
    save(data) {
      switch (data.id) {
        case "ss-number":
          this.updateSSN(data);
          break;
        case "date-of-birth":
          this.updateDOB(data);
          break;
        case "drivers-license":
          this.updateDL(data);
          break;
        case "auto-insurance":
          this.updateAutoInsurance();
          break;
        default:
          console.log("Nothing to update for data", data);
      }
    },
    removeInsurance() {
      this.$emit("onRemoveInsurance");

      // TODO hard delete the image?
    },
    updateInsurance(updateInsuranceData) {
      this.candidateDetails.insuranceExpiry = updateInsuranceData.expirationDate;
      this.$props.candidate.insuranceExpiry = updateInsuranceData.expirationDate;
      const tableEntry = this.screeningTableEntries.filter((screening) => screening.id === "auto-insurance");
      if (tableEntry && tableEntry.length) {
        tableEntry[0].status = "VALID";
      }
      restApi.post("/employee/updateVerifiedinsurance",encodeWithParam({partnerID: this.$props.candidate.partnerID,insuranceVerified: true, userID:this.$store.getters.getUserID}))
      this.updateAutoInsurance();
      micromodal.close("screening-table-insurance-preview");
      const valid = this.screeningTable.reduce((isValid, tableEntry) => isValid && tableEntry.status === "VALID", true);
      this.$emit("input", valid);
    },
    editableItems(data) {
      return data && data.length > 0 ? data.filter((item) => item.editable) : [];
    },
    getLabel(header) {
      return header.editLabel ? header.editLabel : header.label;
    },
    formatDate(dateString) {
      return DateTime.fromISO(dateString).toFormat("dd MMM yyyy");
    },
    roundAge(dateString) {
      return Math.floor(Math.abs(DateTime.fromISO(dateString).diffNow("years").years));
    },
    updateSSN(data) {
      if (this.candidateDetails.ssn !== this.initialValues.ssn) {
        restApi.post("/partner/updateSSN", encodeWithParam({ ssn: this.candidateDetails.ssn, partnerID: this.$props.candidate.partnerID })).finally(() => this.cancel(data));
      }
    },
    updateDOB(data) {
      if (this.candidateDetails.dob !== this.initialValues.dob) {
        restApi.post("/partner/updateDob", encodeWithParam({ dateOfBirth: this.candidateDetails.dob, id: this.$props.candidate.partnerID })).finally(() => this.cancel(data));
      }
    },
    updateDL(data) {
      if (
        this.candidateDetails.licenseNumber !== this.initialValues.ssn ||
        this.candidateDetails.licenseState !== this.initialValues.licenseState ||
        this.candidateDetails.licenseExpiry != this.initialValues.licenseExpiry
      ) {
        restApi
          .post(
            "/partner//updateDL",
            encodeWithParam({
              licenseNumber: this.candidateDetails.licenseNumber,
              licenseState: this.candidateDetails.licenseState,
              licenseExpiry: this.candidateDetails.licenseExpiry,
              partnerID: this.$props.candidate.partnerID,
            })
          )
          .finally(() => this.cancel(data));
      }
    },
    checkMvrSubmitted(progress) {
      let data = progress?.data.result.filter((item)=>(item.candidateStatus == FunnelStatus.MVR_SUBMITTED));
      return data.length > 0 ? true :false;
    },

    candidateProgressObject(status) {
      return {
        assignedOS: this.$props.candidate.specialist,
        partnerID: this.$props.candidate.partnerID,
        stepStatus: status
      };
    },
    updateAutoInsurance() {
      //if (this.candidateDetails.insuranceExpiry !== this.initialValues.insuranceExpiry) {
      let mvrSubmittedFlag = false;
      let insuranceExpiry;
      let dt = new Date(this.$props.candidate.insuranceExpiry);
      insuranceExpiry =`${dt.getFullYear()}-${(dt.getMonth()+1).toString().padStart(2,'0')}-${dt.getDate().toString().padStart(2,'0')}`
      // if (typeof this.$props.candidate.insuranceExpiry == "string") {
      //   insuranceExpiry = this.$props.candidate.insuranceExpiry;
      // } else {
      //   let dt = new Date(this.$props.candidate.insuranceExpiry);
      //   // insuranceExpiry = this.formatDate(this.$props.candidate.insuranceExpiry.toISOString());
      //   insuranceExpiry =`${dt.getFullYear()}-${(dt.getMonth()+1).toString().padStart(2,'0')}-${dt.getDate().toString().padStart(2,'0')}`
      // }
      restApi
        .post("/partner/updateInsurance", encodeWithParam({ insuranceExpiry: insuranceExpiry, partnerID: this.$props.candidate.partnerID, fileId: this.$props.candidate.insuranceFileId }))
        .then(async () => {
          let obj = this.candidateProgressObject(FunnelStatus.OCR_MANUAL_CLEAR)
          await this.updateCandidateProgress(obj);
          restApi.post("/onboard/getFunnelProgress", encodeWithParam({ candidateID: this.$props.candidate.id })).then((data) => {
            mvrSubmittedFlag =this.checkMvrSubmitted(data);
            let triggerMVR = data.data.result.filter((item)=>
                  (item.candidateStatus == FunnelStatus.PAYMENT_AGREEMENT_COMPLETE) ||
                  (item.candidateStatus == FunnelStatus.DOCUMENTS_COMPLETE)
              )
              if(triggerMVR.length >=2 &&  !mvrSubmittedFlag) {
                this.$emit("triggerMVR")
              }
          });
        })
        .catch(() => this.$toast.error("Updating insurance failed"));
      //}
    },
    updateCandidateProgress(candidateProgress) {
      return restApi.post("/onboard/saveCandidateStatus", encodeWithParam(candidateProgress));
    },
    handleInsuranceExpiryDate(date,param) {
      const originalDate = DateTime.fromISO(date, { zone: 'America/Los_Angeles' });
      if (typeof date == "string") {
        if(param == 'display'){
          let str=originalDate.toString()
          return str.split('T')[0]
        }
        return originalDate.toString()
      } else {
        return date ? this.formatDate(date.toISOString()) : '';
      }
    },
  },
};
</script>
