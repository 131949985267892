<template>
  <div class="current-step">
    <div>
      <div v-if="currentStep.checkrOnboardError && !currentStep.mvrSubmittedDate" style="padding-bottom: 10px">
        <span style="color: #c2272c">Error Onboarding candidate to checkr</span>
        <span style="color: #c2272c; padding-left: 5px"> {{ currentStep.reportErrorDate }}</span>
      </div>
      <div v-if="currentStep.mvrSubmittedDate" style="padding-bottom: 10px">
        <span> Mvr Submitted</span>
        <span style="color: #747474; padding-left: 5px"> {{ currentStep.mvrSubmittedDate }}</span>
      </div>
      <div v-if="currentStep.reportErrorDateMVR && !currentStep.mvrSubmittedDate" style="padding-bottom: 10px">
        <span style="color: #c2272c">Error ordering mvr report</span>
        <span style="color: #c2272c; padding-left: 5px"> {{ currentStep.reportErrorDate }}</span>
      </div>
      <div v-if="currentStep.mvrClearDate" style="padding-bottom: 10px">
        <span> Mvr Eligible</span>
        <span style="color: #747474; padding-left: 5px"> {{ currentStep.mvrClearDate }}</span>
      </div>
      <div v-if="currentStep.mvrsubmittedneededdate" style="padding-bottom: 10px">
        <span> MVR Submitted Needed</span>
        <span style="color: #747474; padding-left: 5px"> {{ currentStep.mvrsubmittedneededdate }}</span>
      </div>
      <div v-if="currentStep.mvrConsiderDate" style="padding-bottom: 10px">
        <span> Mvr Consider</span>
        <span style="color: #747474; padding-left: 5px"> {{ currentStep.mvrConsiderDate }}</span>
      </div>
      <div v-if="currentStep.mvrDeniedDate" style="padding-bottom: 10px">
        <span>MVR Denied</span>
        <span style="color: #747474; padding-left: 5px"> {{ currentStep.mvrDeniedDate }}</span>
      </div>
      <div v-if="currentStep.packageSubmittedDate" style="padding-bottom: 10px">
        <span> CBC Submitted</span>
        <span style="color: #747474; padding-left: 5px"> {{ currentStep.packageSubmittedDate }}</span>
      </div>
      <div v-if="currentStep.reportErrorDateCBC && !currentStep.packageSubmittedDate" style="padding-bottom: 10px">
        <span style="color: #c2272c">Error ordering cbc report</span>
        <span style="color: #c2272c; padding-left: 5px"> {{ currentStep.reportErrorDate }}</span>
      </div>
      <div v-if="currentStep.cbcPromotedDate" style="padding-bottom: 10px">
        <span> CBC Force Promoted</span>
        <span style="color: #747474; padding-left: 5px"> {{ currentStep.cbcPromotedDate }}</span>
      </div>
      <div v-if="currentStep.packageClear && !currentStep.cbcPromotedDate" style="padding-bottom: 10px">
        <span> CBC Eligible</span>
        <span style="color: #747474; padding-left: 5px"> {{ currentStep.packageClear }}</span>
      </div>
      <div v-if="currentStep.packageConsider && !currentStep.cbcPromotedDate" style="padding-bottom: 10px">
        <span> CBC Returned Consider</span>
        <span style="color: #747474; padding-left: 5px"> {{ currentStep.packageConsider }}</span>
      </div>
      <div v-if="currentStep.screeningDenied" style="padding-bottom: 10px">
        <span>CBC Denied</span>
        <span style="color: #747474; padding-left: 5px"> {{ currentStep.screeningDenied }}</span>
      </div>
      <!-- <status-date v-if="manual" :status="'Approved Manual'" :current-step="currentStep"> </status-date> -->
      <div v-if="manual">
        <div style="padding-bottom: 10px">
          <span> Approved Manual</span>
          <span style="color: #747474; padding-left: 5px"> {{ currentStep.approvedTimeStamp }}</span>
        </div>
      </div>
      <div v-if="approvedAutoC">
        <div style="padding-bottom: 10px">
          <span> Approved Auto</span>
          <span style="color: #747474; padding-left: 5px"> {{ currentStep.approvedTimeStamp }}</span>
        </div>
      </div>
      <div v-if="displayCheckrLink" style="padding-bottom: 20px; padding-top: 20px">
        <a :href="reportLinks" target="_blank">View in Checkr<i class="icon-external-link right-icon"></i></a>
      </div>
      <div v-if="currentStep.displayScreeningFlag" style="padding-top: 30px">
        <label>Screening Status</label>
        <span style="display: block; padding-top: 5px">{{ currentStep.screeningStatus }} {{ checkrData.checkrStatus ? ` - ${checkrData.checkrStatus}` : '' }}</span>
      </div>

      <div v-if="(currentStep.screeningStatus === 'MVR Returned Consider') && ((!isNewCheckrFlowApplicable || isInPostAdverseAction) || isOldCheckrFlowCandiate)" style="padding-top: 20px">
        <div v-if="hideRunPackage">
          <div v-if="(!isNewCheckrFlowApplicable || isOldCheckrFlowCandiate)" class="radio-custom">
            <input id="approveMVR" v-model="runScreening" type="radio" :disabled="!getPermission('adjudicatCheckr')" name="screening-run" value="yes" @click="enableSaveButton" />
            <label for="approveMVR"><span class="label-container">Run CBC</span></label>
          </div>
          <div style="padding-bottom: 15px" class="radio-custom">
            <input id="denyMVR" v-model="runScreening" type="radio" :disabled="!getPermission('adjudicatCheckr')" name="screening-run" value="no" @click="enableSaveButton" />
            <label for="denyMVR"><span class="label-container">Deny</span></label>
          </div>
          <button class="button" :disabled="!saveEnabled" @click="runPackage">Save</button>
        </div>
      </div>
      <div v-if="currentStep.screeningStatus === 'CBC Returned Consider' && $props.data.currentStep.step === 'CBC' && ((!isNewCheckrFlowApplicable || isInPostAdverseAction) || isOldCheckrFlowCandiate)" style="padding-top: 20px">
        <div v-if="(!isNewCheckrFlowApplicable || isOldCheckrFlowCandiate)" class="radio-custom">
          <input id="approveCBC" v-model="runScreening" type="radio" name="screening-run" :disabled="!getPermission('adjudicatCheckr')" value="yes" @click="enableSaveButton" />
          <label for="approveCBC"><span class="label-container">Approve</span></label>
        </div>
        <div style="padding-bottom: 15px" class="radio-custom">
          <input id="denyCBC" v-model="runScreening" type="radio" name="screening-run" :disabled="!getPermission('adjudicatCheckr')" value="no" @click="enableSaveButton" />
          <label for="denyCBC"><span class="label-container">Deny</span></label>
        </div>
        <button class="button" :disabled="!saveEnabled" @click="manualApproval('')">Save</button>
        <p class="error-message">{{ checkrError }}</p>
      </div>
      <div v-if="isInNeedsReview && isNewCheckrFlowApplicable" style="padding-top: 20px">
        <div class="radio-custom" v-if="getPermission('withdrawApplicant')">
          <input id="approveUpdatedCandidate" v-model="approveUpdatedCandidate" type="radio" name="screening-run" :disabled="!getPermission('adjudicatCheckr')" value="yes" @click="enableSaveButton" />
          <label for="approveUpdatedCandidate"><span class="label-container">Approve</span></label>
        </div>
        <div v-if="getPermission('withdrawApplicant')" style="padding-bottom: 15px" class="radio-custom">
          <input id="withdraw-approve" type="radio" v-model="approveUpdatedCandidate" name="withdraw-candidate" :disabled="!getPermission('adjudicatCheckr')" value="no" @click="enableSaveButton" />
          <label for="withdraw-approve"><span class="label-container">WITHDRAW</span></label>
        </div>
        <button class="button" :disabled="!saveEnabled" @click="handleUpdatedCandiate()">Save</button>
      </div>
    </div>

    <!-- <p v-if="candidateNeedsDirection">Direct a candidate to their application</p> -->
    <!-- <status-date v-if="screeningSubmitted" :current-step="currentStep" status="Not Returned"> </status-date> -->
    <communications-status :current-step="currentStep" />
    <!-- <div v-if="withdrawn">
      <status-date :current-step="currentStep"> </status-date>
      <div class="margin-left-12 withdraw-reason-header">
        Reason: <br />
        <p class="withdraw-reason-content">{{ currentStep.stepDetail }}</p>
      </div>
    </div> -->
    <!-- <div v-if="screeningPastNeeded">
      <label>Screening Status</label>
      <p>{{ currentStep.status }}</p>
    </div> -->
    <div v-if="isCompleted && showForceApprovalSelection && hideHCSelectionCBCDenied">
      <label>Location HC fulfillment</label>
      <p>{{ candidate.locationCountCurrent === null ? 0 : candidate.locationCountCurrent }} Approved / {{ candidate.locationCountTotal === null ? 0 : candidate.locationCountTotal }} Requested</p>
      <div class="module inner">
        <div v-if="getPermission('approveApplicant')" class="radio-custom">
          <input id="force-approve" type="radio" :checked="approval.forced === 'FORCE'" value="FORCE" name="approval-stat" @change="updateForceApproval($event.target.value)" />
          <label for="force-approve"><span class="label-container">Force Approval</span></label>
        </div>
        <div v-if="getPermission('withdrawApplicant')" class="radio-custom">
          <input id="withdraw-approve" type="radio" :checked="approval.forced === 'WITHDRAW'" value="WITHDRAW" name="approval-stat" @change="updateForceApproval($event.target.value)" />
          <label for="withdraw-approve"><span class="label-container">WITHDRAW</span></label>
        </div>
      </div>
      <button v-if="getPermission('approveApplicant') || getPermission('withdrawApplicant')" class="button" @click="saveForce">Save</button>
    </div>
    <div v-if="screeningNeeded" class="needs-screening margin-top-44">
      <p>Verify this personal info before running a screening package for this candidate:</p>
      <screening-table
        v-model="screeningVerified"
        :candidate="$props.candidate"
        :personal-details="$props.personalDetails"
        :partner-steps="$props.partnerSteps"
        @onRemoveInsurance="removeInsuranceImage"
        @insuranceImageUploaded="insuranceImageUploaded"
        @triggerMVR="runScreeningPackage"
      />
      <button class="button" :disabled="checkrLoading" @click="runScreeningPackage">
        Run Screening Package <span v-if="checkrLoading" class="loading"><Loader /></span>
      </button>
      <p class="error-message">{{ checkrError }}</p>
    </div>
    <div>
      <hr />
      <expansion-panel label="Insurance Failure Reason">
        <ul>
          <li v-for="reason in this.$props.candidate.personaOCRInsuranceFailureReasons" :key="reason">
            <p>{{ reason }}</p>
          </li>
        </ul>
      </expansion-panel>
    </div>
    <status-date v-if="isFullyApproved" :current-step="currentStep" :status="approvedLabel" />
    <eligibility-questions v-if="showQualifyingQuestions" :eligibility-questions="data.eligibilityQuestions" @onSaveQualifyingAnswers="saveQualifyingAnswers" />
    <personal-details v-if="showPersonalDetails && getPermission('viewAllPII')" :candidate="candidate" :personal-details="$props.personalDetails" @onSavePersonalDetail="savePersonalDetails" />
    <documents v-if="showDocuments" :funnel-documents="funnelDocuments" :partner-id="$props.candidate.userId" :isCandidateFlyoutDataLoading="$props.isCandidateFlyoutDataLoading"/>
    <preview-insurance-modal
    :id="'maintenance-table-insurance-preview'"
      :data="insuranceModalData"
      :partnerPageInsuranceMicroModal="partnerPageInsuranceMicroModal"
      :modalKey="modalKey"
      :isInsuranceLoading="this.$props.candidate.isInsuranceFileLoading"
      @onRemove="removeInsuranceImage"
      @onSave="updateInsurance"
      @onCancel="closeModal"
      @imageChanged="insuranceImageUploaded"
    />
    <div v-if="viewInsPreview && getPermission('viewUpldInsurance')">
      <hr class="qualifying-line" />
      <a class="button ghost" @click="showInsuranceModal"> View/Edit Insurance <i class="icon icon-external-link"></i></a>
    </div>
  </div>
</template>
<script>
import { OnboardingStatus } from "@/modules/admin/partners/model";
import ScreeningTable from "@/modules/admin/onboarding/components/ScreeningTable";
import { Loader } from "@/components";
import { CommunicationsStatus, StatusDate } from "@/modules/admin/onboarding/components/status";
import EligibilityQuestions from "@/modules/admin/onboarding/components/EligibilityQuestions";
import PersonalDetails from "@/modules/admin/onboarding/components/PersonalDetails";
import Documents from "@/modules/admin/onboarding/components/Documents";
import { DateTime } from "luxon";
import PreviewInsuranceModal from "@/modules/admin/onboarding/modals/PreviewInsuranceModal";
import micromodal from "micromodal";
import { restApi } from "@/http/http.config";
import { encodeWithParam } from "@/util/Base64Encoding";
import { mapGetters } from "vuex";
// import { formatDatePacific } from "@/filters";
import {FunnelStages,FunnelSteps,FunnelStatus,FunnelProgress, funnelSubStatues} from "@/util/funnelProgress";
import { ExpansionPanel } from "@/components";

export default {
  components: { PersonalDetails, EligibilityQuestions, StatusDate, CommunicationsStatus, ScreeningTable, Documents, Loader, PreviewInsuranceModal, ExpansionPanel },
  filters: {
    formatStatus(status) {
      return status ? OnboardingStatus.getDisplay(status) : "";
    },
  },
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    data: {
      type: Object,
      required: true,
      default: () => {},
    },
    candidate: {
      type: Object,
      required: true,
      default: () => {},
    },
    personalDetails: {
      type: Object,
      required: true,
      default: () => {},
    },
    partnerSteps: {
      type: Array,
      required: false,
      default: () => [],
    },
    isPartnerPage: {
      type: Boolean,
      required: false,
      default: false,
    },
    isCandidateFlyoutDataLoading: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  data() {
    return {
      fp: new FunnelProgress(),
      screeningVerified: false,
      approval: {},
      checkrLoading: false,
      checkrError: "",
      tempExpiry: "",
      displayScreeningFlag: true,
      runScreening: "",
      approveUpdatedCandidate: "",
      insuranceImageURL: "",
      saveEnabled: false,
      hideRunPackage: true,
      reportsConfig: {},
      modalKey: 0,
      partnerPageInsuranceMicroModal: false,
      showForceApprovalSelection: true,
      hideHCSelectionCBCDenied:true
    };
  },
  computed: {
    ...mapGetters(["getUsername", "getPermission"]),
    approvedAutoC() {
      return this.$props.candidate.status === FunnelStatus.APPROVED_AUTO;
    },
    currentStep() {
      return this.$props.data && this.$props.data.currentStep ? this.$props.data.currentStep : {};
    },
    checkrData() {
      return this.$props.data && this.$props.data?.checkrData ? this.$props.data.checkrData : {};
    },
    isScreening() {
      return this.currentStep.stage === FunnelStages.RISK_MITIGATION;
    },
    isCompleted() {
      return this.$props?.partnerSteps[0]?.status === FunnelStatus.APPLICATION_WAITLIST ? true: false
    },
    isApproved() {
      return this.currentStep.step === FunnelSteps.APPLICATION_APPROVED;
    },
    isFullyApproved() {
      return this.isApproved;
    },
    manual() {
      return this.currentStep.status === FunnelStatus.APPROVED_MANUAL;
    },
    screeningNeeded() {
      return (
        [FunnelStatus.OCR_FAILED,FunnelStatus.INSURANCE_IMAGE_INVALID].includes(this.currentStep.status) 
      );
    },
    isInPostAdverseAction() {
        return this.checkrData.checkrStatus === funnelSubStatues.POST_AA;
    },
    isInNeedsReview() {
      return this.$props?.partnerSteps[0]?.status === FunnelStatus.NEEDS_REVIEW ? true: false;
    },
    isNewCheckrFlowApplicable() {
      return this.checkrData.isNewCheckrFlow ? true : false;
    },
    isOldCheckrFlowCandiate() {
      return (this.checkrData?.checkrStatus) ? false :  true;
    },
    viewInsPreview() {
      return (
        [FunnelStatus.INSURANCE_UPLOAD_COMPLETE,FunnelStatus.PAYMENT_AGREEMENT_PENDING,FunnelStatus.PAYMENT_AGREEMENT_COMPLETE,
        FunnelStatus.OCR_FAILED,FunnelStatus.INSURANCE_IMAGE_INVALID,FunnelStatus.OCR_MANUAL_CLEAR,FunnelStatus.OCR_CLEAR,
        FunnelStatus.MVR_SUBMITTED,FunnelStatus.MVR_PENDING,FunnelStatus.MVR_SUSPENDED,FunnelStatus.MVR_CLEAR,FunnelStatus.MVR_CONSIDER,
        FunnelStatus.MVR_DENIED,FunnelStatus.CBC_SUBMITTED,FunnelStatus.CBC_PENDING,FunnelStatus.CBC_SUSPENDED,FunnelStatus.CBC_CLEAR,
        FunnelStatus.CBC_CONSIDER,FunnelStatus.CBC_DENIED,FunnelStatus.APPROVED_AUTO,FunnelStatus.APPROVED_MANUAL,FunnelStatus.WITHDRAWN,FunnelStatus.APPLICATION_WAITLIST
        ].includes(this.currentStep.status) 
      )
    },
    displayLicencePreview() {
      return this.isScreening || this.isCompleted || this.isApproved ? true : false;
    },
    showQualifyingQuestions() {
      return this.$props.data.steps.findIndex((step) => step.status === FunnelStatus.ENROLLMENT_COMPLETE) >= 0;
    },
    showPersonalDetails() {
      return this.$props.data.steps.findIndex((step) => step.status === FunnelStatus.PERSONAL_DETAILS_COMPLETE ) >= 0;
    },
    showDocuments() {
      return (this.$props.data.steps.findIndex((step) => step.status === FunnelStatus.DOCUMENTS_COMPLETE) >= 0) && this.getPermission('dwnldDocuments');
    },
    funnelDocuments() {
      return this.$props.candidate.allDocs ? this.$props.candidate.allDocs : [];
    },
    approvedLabel() {
      return `${OnboardingStatus.getDisplay(this.currentStep.status)} ${this.currentStep?.statusDetail ? this.currentStep.statusDetail:""}`;
    },
    insuranceModalData() {
      return {
        insuranceImageURL: this.$props.candidate.insuranceImageURL,
        isPDF:this.$props.candidate?.insuranceFileFormat ?? false,
        insuranceExpiry:this.handleInsuranceExpiryDate(this.$props.candidate.insuranceExpiry),
        funnelFilesID: this.funnelFilesId,
        locationPartnerID: this.$props.candidate.id,
        locationID: this.$props.candidate.locationID,
        clientID: this.$props.candidate.clientID,
        partnerID: this.$props.candidate.partnerID,
        documentTemplateID: this.getInsuranceDocumentsTemplateID(this.$props.candidate.allDocs),
        signature: this.$props.candidate.electronicSignature ? this.$props.candidate.electronicSignature : "",
        userID: this.$props.candidate.userId,
        specialist: this.$props.candidate.specialist,
        phone:this.$props.candidate.phone,
        insuranceFileId:this.$props.candidate.insuranceFileId  
      };
    },
    reportLinks() {
      return `${this.reportsConfig.rootCheckrLink}/candidates/${this.$props.candidate.externalID}${this.reportsConfig.checkrTestQuery}`;
    },
    displayCheckrLink() {
      return this.$props.data.currentStep.mvrSubmittedDate ? true : false;
    },
  },
  mounted() {
    restApi.get("onboard/getCheckConfig").then((data) => {
      this.reportsConfig = data.data.result.length ? JSON.parse(data.data.result[0].value) : { rootCheckrLink: "", checkrTestQuery: "" };
    });
  },
  methods: {
    insuranceImageUploaded(data) {
      this.$props.candidate.insuranceFileId = data.fileID;
      this.$props.candidate.insuranceImageURL = data.fileURL;
      this.$emit("insuranceImageUploaded");
    },
    removeInsuranceImage() {
      this.$emit("onRemoveInsurance");
    },
    async runScreeningPackage() {
      restApi.post("/onboard/getFunnelProgress", encodeWithParam({ candidateID: this.$props.candidate.id })).then(async (data)=>{
      const isCompleted = data.data.result.filter((progress) => {
        return progress.candidateStatus == FunnelStatus.PAYMENT_AGREEMENT_COMPLETE;
      });
      const insuranceVerified = data.data.result.filter((progress) => progress.candidateStatus == FunnelStatus.OCR_MANUAL_CLEAR ||progress.candidateStatus === FunnelStatus.OCR_CLEAR);
      const isMVR = data.data.result.filter((progress) => {
        return progress.candidateStatus == FunnelStatus.MVR_SUBMITTED
      });
      if (isCompleted.length > 0 && isMVR.length == 0 && insuranceVerified.length > 0)  {
        this.checkrLoading = true;
        this.checkrError = "";
        let candidate = this.$props.candidate;
        //candidate["allDocs"] = [];
        let updatedlocationID = await restApi.post("/onboard/getLocationID", encodeWithParam({ partnerID: this.$props.candidate.partnerID }));
        candidate.locationID = updatedlocationID.data.result[0].clientLocationID;
        this.$toast.success("MVR is Triggering");
        restApi.post("/onboard/triggerMVR", encodeWithParam({id:candidate.partnerID,locationID: candidate.locationID})).then(()=>{
            this.screeningVerified = true;
            this.checkrLoading = false;
            this.$emit("refresh", {});
        }).catch((error)=>{
           this.checkrLoading = false;
            this.checkrError = error.error;
        })
      } else {
        if(insuranceVerified.length === 0) {
          this.$toast.error("Insurance is not verified");
        } else if(isCompleted.length === 0) {
          this.$toast.error("Payment Agreement is Pending");
        }
        else {
          this.$toast.error("MVR already triggered");
        }
      }
      })
    },
    enableSaveButton() {
      this.saveEnabled = true;
    },
    async runPackage() {
      if (this.runScreening === "yes") {
        let candidate = this.$props.candidate;
        //candidate["allDocs"] = [];
        let updatedlocationID = await restApi.post("/onboard/getLocationID", encodeWithParam({ partnerID: this.$props.candidate.partnerID }));
        candidate.locationID = updatedlocationID.data.result[0].clientLocationID;
        restApi.post("/onboard/triggerCBC", encodeWithParam({id:this.$props.candidate.partnerID,locationID: candidate.locationID})).catch((error)=>{
            this.checkrError = error.error;
        })
      } else {
        const candidateProgress = this.candidateProgressObject(FunnelStatus.MVR_DENIED);
        this.updateCandidateProgress(candidateProgress);
      }
      this.hideRunPackage = false;
    },
    saveQualifyingAnswers(eligibilityInfo) {
      this.$emit("onSaveQualifyingAnswers", eligibilityInfo);
    },

    savePersonalDetails(personalDetailsInfo) {
      this.$emit("onSavePersonalDetails", personalDetailsInfo);
    },
    updateForceApproval(e) {
      this.approval.forced = e;
    },
    removeInsurance(removeInsuranceData) {
      console.log(removeInsuranceData);
      this.insuranceImageURL = null;
      // TODO hard delete the image?
    },
    sendToDms(approvalType) {
      restApi.post(`/walmart/onboarding`, encodeWithParam({ partnerID:this.candidate.partnerID,approvalType: approvalType }))
      this.$toast.success("Request Sent to DMS")
    },

    approvedTotalCount() {
      let approvedCount = this.candidate.locationCountCurrent === null ? 0 : this.candidate.locationCountCurrent;
      let totalCount = this.candidate.locationCountTotal === null ? 0 : this.candidate.locationCountTotal;
      return {approvedCount,totalCount}
    },
    saveForce() {
      this.showForceApprovalSelection = false;
      if (this.approval.forced === "FORCE") {
          const candidateProgress = this.candidateProgressObject(FunnelStatus.APPLICATION_WAITLIST);
          if(!this.isCompleted) {
            this.updateCandidateProgress(candidateProgress);
          }
          this.sendToDms(FunnelStatus.APPROVED_MANUAL)
      }
      if (this.approval.forced === "WITHDRAW") {
          const candidateProgress = this.candidateProgressObject(FunnelStatus.WITHDRAWN);
          this.updateCandidateProgress(candidateProgress);
          this.$toast.error("Withdrawn")
      }
    },
    async manualApproval() {
      if (this.runScreening === "yes") {
         const candidateProgress = this.candidateProgressObject(FunnelStatus.APPLICATION_WAITLIST);
         this.updateCandidateProgress(candidateProgress);
         const {approvedCount,totalCount} = this.approvedTotalCount();
         if((approvedCount < totalCount)) {
            const isCandidateAutoApprovalEligible = await this.checkIsCandidateAutoApprovalEligible();
            if(isCandidateAutoApprovalEligible) {
              this.hideHCSelectionCBCDenied = false;
              this.sendToDms(FunnelStatus.APPROVED_AUTO)
            } else {
              this.$toast.error("Candidate not eligible for approval");
            }
         } else {
          this.$toast.error("Head Count Request Full")
         }

      } else {
         const candidateProgress = this.candidateProgressObject(FunnelStatus.CBC_DENIED);
         this.updateCandidateProgress(candidateProgress);
      }
     
    },
    async handleUpdatedCandiate() {
      if (this.approveUpdatedCandidate === "yes") {
         const candidateProgress = this.candidateProgressObject(FunnelStatus.APPLICATION_WAITLIST);
         this.updateCandidateProgress(candidateProgress);
         const {approvedCount,totalCount} = this.approvedTotalCount();
         if(approvedCount < totalCount ) {
            this.hideHCSelectionCBCDenied = false;
            this.sendToDms(FunnelStatus.APPROVED_AUTO)
         } else {
          this.$toast.error("Head Count Request Full")
         }

      } else {
        const candidateProgress = this.candidateProgressObject(FunnelStatus.WITHDRAWN);
        this.updateCandidateProgress(candidateProgress);
        this.$toast.error("Withdrawn")
      }
    },
    updateCandidateProgress(candidateProgress) {
      return restApi.post("/onboard/saveCandidateStatus", encodeWithParam(candidateProgress));
    },
    setApprovalDate() {
      restApi.post("/onboard/setApprovalDate", encodeWithParam({ id: this.candidate.id, approvalDate: DateTime.utc().toJSDate() }));
    },
    candidateProgressObject(status) {
      return {
        assignedOS: this.candidate.specialist,
        partnerID: this.candidate.partnerID,
        stepStatus: status,
        partnerStepStatus:this.approveUpdatedCandidate=='yes'?this.$props?.partnerSteps[0]?.status:""
        //status check
      };
    },

    checkMvrSubmitted(progress) {
      let data = progress?.data.result.filter((item)=>( item.candidateStatus == FunnelStatus.MVR_SUBMITTED));
      return data.length > 0 ? true :false;
    },
    updateAutoInsurance() {
      if (this.$props.candidate.partnerID) {
        let dt = new Date(this.tempExpiry)
        let formattedDate= `${dt.getFullYear()}-${(dt.getMonth()+1).toString().padStart(2,'0')}-${dt.getDate().toString().padStart(2,'0')}`
        let mvrSubmittedFlag = false;
        restApi
          .post("/partner/updateInsurance", encodeWithParam({ insuranceExpiry: formattedDate, partnerID: this.$props.candidate.partnerID, fileId: this.$props.candidate.insuranceFileId  }))
          .then(async () => {
            const obj = this.candidateProgressObject(FunnelStatus.OCR_MANUAL_CLEAR);
            await this.updateCandidateProgress(obj);
            restApi.post("/onboard/getFunnelProgress", encodeWithParam({ candidateID: this.$props.candidate.id })).then((data) => {
              mvrSubmittedFlag =this.checkMvrSubmitted(data);
              if(!mvrSubmittedFlag) {
                this.runScreeningPackage();
              }
            });
          })
          .catch(() => this.$toast.error("Updating insurance failed"));
      }
    },
    updateInsurance(updateInsuranceData) {
      if (typeof updateInsuranceData.expirationDate == "string") {
        this.tempExpiry = updateInsuranceData.expirationDate;
      } else {
        this.tempExpiry = this.formatDate(updateInsuranceData.expirationDate.toISOString());
        // let dt = new Date(updateInsuranceData.expirationDate)
        // this.tempExpiry = `${dt.getFullYear()}-${(dt.getMonth()+1).toString().padStart(2,'0')}-${dt.getDate().toString().padStart(2,'0')}`
      }
      this.$props.candidate.insuranceExpiry = this.tempExpiry;
      restApi.post("/employee/updateVerifiedinsurance",encodeWithParam({partnerID: this.$props.candidate.partnerID,insuranceVerified: true, userID:this.$store.getters.getUserID}))
      this.updateAutoInsurance();
      this.closeModal();
    },
    formatDate(dateString) {
      return DateTime.fromISO(dateString).toFormat("dd MMM yyyy");
    },
    showInsuranceModal() {
      if (this.$props.isPartnerPage) {
        this.partnerPageInsuranceMicroModal = true  
      }
      micromodal.show("maintenance-table-insurance-preview", {});
    },
    closeModal() { 
      if (this.$props.isPartnerPage) {
        this.partnerPageInsuranceMicroModal = false
      }

      micromodal.close("maintenance-table-insurance-preview");
    },
    getInsuranceDocumentsTemplateID(docs) {
      if (docs?.length > 0) {
        let insuranceID;
        insuranceID = docs?.filter((item) => item.title.toLowerCase() === "insurance");
        return insuranceID[0].id;
      } else {
        return "";
      }
    },
    handleInsuranceExpiryDate(date) {
      const originalDate = DateTime.fromISO(date, { zone: 'America/Los_Angeles' });
      if (typeof date == "string") {
        return originalDate.toString()
      } else {
        return date ? this.formatDate(date.toISOString()) : '';
      }
    },
    async checkIsCandidateAutoApprovalEligible(){
      return restApi.post("/onboard/checkIsCandidateAutoApprovalEligible", encodeWithParam({ partnerID: this.$props.candidate.partnerID })).then((res) => {
        return res?.data?.success ? true : false;
      }).catch(() => {
        return false;
      })
    },
  }, 
  watch: {
      modalKey(oldKey, newKey) {
        if (oldKey != newKey) {
          console.log(newKey)
          return newKey
        }
      }
    }
};
</script>

<style scoped>
.margin-top-44 {
  margin-top: 44px !important;
}
</style>
